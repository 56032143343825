import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import "./blog-post.css"

const supporters = [
  {name: "Adam Feldman", url: "https://github.com/adamfeldman"},
  {name: "Albrecht Schmidt"},
  {name: "Alister Lee"},
  {name: "Austin Finlinson"},
  {name: "Christian Paul Dehli", url: "https://github.com/dehli"},
  {name: "Cognitect + Nubank", url: "https://cognitect.com/blog/2020/12/15/sponsoring-open-source-developers"},
  {name: "Daemian Mack"},
  {name: "Erich Oliphant", url: "https://github.com/eoliphan"},
  {name: "Eric Caspary", url: "https://github.com/err"},
  {name: "Fluent", url: "https://fluent.to/"},
  {name: "Jochen Bedersdorfer"},
  {name: "Juan E. Maya", url: "https://github.com/jmayaalv"},
  {name: "Kendall Buchanan"},
  {name: "Mauricio Szabo", url: "https://github.com/mauricioszabo/"},
  {name: "Mark Addleman", url: "https://linkedin.com/in/mark-addleman-55bb74"},
  {name: "Mark Wardle"},
  {name: "Michael Glaesemann"},
  {name: "Oleg, Iar, Anton"},
  {name: "Paulo Feodrippe", url: "https://github.com/pfeodrippe"},
  {name: "West"},
]

const FollowCloser = () => {
  return (
    <React.Fragment>
      <h4>Follow closer</h4>
      <p>
        If you like to know in more details about my projects check my&nbsp;
        <a href="https://roamresearch.com/#/app/wsscode">open Roam database</a>
        &nbsp;where you can see development details almost daily.
      </p>
    </React.Fragment>)
}

const PatreonRequest = () => {
  return (
    <React.Fragment>
      <h4>Support my work</h4>
      <p>
        I'm currently an independent developer and I spent quite a lot of my personal
        time doing open-source work. If my work is valuable for you or your company,
        please consider supporting my work though <a href="https://www.patreon.com/wsscode" target="_blank">Patreon</a>,
        this way you can help me have more available time to keep doing this work. Thanks!
      </p>
      <h4>Current supporters</h4>
      <p>And here I like to give a thanks to my current supporters:</p>
      <div className="supporters-columns">
        { supporters.map(({name, url}) => {
          return <div key={name}>{ url ? <a href={url}>{name}</a> : name } </div>
        })}
      </div>
    </React.Fragment>)
}

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
          </p>
        </header>
        <MDXRenderer>{post.body}</MDXRenderer>
        <hr style={{
          marginBottom: rhythm(0)
        }} />
        <FollowCloser />
        <PatreonRequest />
        <hr style={{
          marginTop: rhythm(1),
          marginBottom: rhythm(1)
        }} />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
